
import { defineComponent } from 'vue'
import {
  DspInstruction,
  InstructionDsp,
  MaxFrequency,
  OverwritesParam,
  OverwriteValue
} from '../../../../../types/instruction_type'
import { OverwritingFieldConfig } from '../../../../../config/dspConfigPerDsp/dspConfigPerDspTypes'
import type { PropType } from 'vue'
import { OverwriteFrequencyInput } from '../../Form/FormFragments'
import HelpTooltip from '@/components/Common/HelpTooltip.vue'

export default defineComponent({
  name: 'BulkOverwrite',
  props: {
    ioList: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    value: {
      type: Object as PropType<OverwritesParam>,
      required: true
    },
    simulationErrors: {
      type: [String, Object],
      required: false
    }
  },
  components: {
    HelpTooltip,
    OverwriteFrequencyInput
  },
  data () {
    return {
      isOverwriteFrequencyNA: true,
      overwriteBudget: 'N/A',
      itemUnknownDemographic: [
        { text: 'N/A', value: 'N/A' },
        { text: 'always', value: 'always' },
        { text: 'only if', value: 'only_if' },
        { text: 'never', value: 'never' }
      ] as TextValueType<string>[],
      overwriteNotUpdatable: [] as (keyof OverwritesParam)[],
      tooltipInfo: {
        allow_switch_io_pacing: {
          text: 'Warning: This option will not apply if we are on the last day of the billing period, ' +
            'and there is no new billing detected at the IO level.'
        }
      }
    }
  },
  methods: {
    setDefaultOverwrites () {
      const overwrites: OverwritesParam = {
        overwrite_unknown_demographic: 'N/A'
      } as OverwritesParam
      for (const field of this.dspOverwritingsBoolean) {
        overwrites[field.value] = 'N/A'
      }
      this.$emit('input', overwrites)
    },
    emitUpdateOverwrite (field: keyof DspInstruction, value: OverwriteValue) {
      this.$emit('input', { ...this.value, [field]: value })
    },
    updateMaxFrequency (newValue: MaxFrequency) {
      this.emitUpdateOverwrite('max_frequency', newValue)
    },
    updateOverwriteFrequency (newValue: boolean) {
      if (this.isOverwriteFrequencyNA) {
        this.emitUpdateOverwrite('overwrite_frequency', 'N/A')
      } else {
        this.emitUpdateOverwrite('overwrite_frequency', newValue)
      }
    },
    onClickNAOverwriteFrequency () {
      this.emitUpdateOverwrite('overwrite_frequency', 'N/A')
      this.emitUpdateOverwrite('max_frequency', {})
    },
    onChangeOverwriteBudget (value: string) {
      if (value === 'N/A') {
        this.emitUpdateOverwrite('remove_daily_budget_global', 'N/A')
        this.emitUpdateOverwrite('remove_daily_budget_campaigns_only', 'N/A')
      } else if (value === 'global') {
        this.emitUpdateOverwrite('remove_daily_budget_global', true)
        this.emitUpdateOverwrite('remove_daily_budget_campaigns_only', false)
      } else if (value === 'off') {
        this.emitUpdateOverwrite('remove_daily_budget_global', false)
        this.emitUpdateOverwrite('remove_daily_budget_campaigns_only', false)
      } else if (value === 'campaign') {
        this.emitUpdateOverwrite('remove_daily_budget_global', false)
        this.emitUpdateOverwrite('remove_daily_budget_campaigns_only', true)
      }
    }
  },
  mounted () {
    this.setDefaultOverwrites()
  },
  computed: {
    dspOverwriting (): OverwritingFieldConfig[] {
      const overwritingsFields = this.$dspConfig[this.$route.params.dsp as InstructionDsp].overwritingFields
      return [...overwritingsFields.normal, ...overwritingsFields.advanced]
    },
    dspOverwritingsBoolean (): OverwritingFieldConfig[] {
      const dspOverwriting: OverwritingFieldConfig[] = this.dspOverwriting
      return dspOverwriting.filter((field) => !field.isBtnToggle && !this.overwriteNotUpdatable.includes(field.value))
    },
    getLabelIo (): string {
      return this.$dspConfig[this.$route.params.dsp as InstructionDsp].ioFieldLabel
    },
    isDisplaySimulationErrors (): boolean {
      return this.simulationErrors && Object.keys(this.simulationErrors).length > 0
    },
    displaySimulationErrors (): string {
      if (!this.isDisplaySimulationErrors) {
        return ''
      }

      if (typeof this.simulationErrors === 'string') {
        return this.simulationErrors
      }
      // @ts-ignore
      return Object.keys(this.simulationErrors).map((key) => this.simulationErrors[key])
    },
    displayBudget (): boolean {
      return this.dspOverwriting.some((field) => field.label === 'Budget')
    },
    displayOverwriteUnknownDemographic (): boolean {
      return ['dbm', 'youtube'].includes(this.$route.params.dsp)
    }
  }
})
